<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A population of mouse leukemia cells grown in cell culture was found to take
        <number-value :value="t1" unit="\text{hours}" />
        to double at
        <number-value :value="T1" unit="^\circ\text{C,}" />
        while at
        <stemble-latex content="$37.0\,^\circ\text{C},$" />
        this only took
        <number-value :value="t2" unit="\text{hours.}" />
        Calculate the energy of activation for the growth of these leukemia cells.
      </p>

      <calculation-input
        v-model="inputs.Ea"
        prepend-text="$\text{E}_\text{a}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'Question473',
  components: {
    ChemicalLatex,
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Ea: null,
      },
    };
  },
  computed: {
    t1() {
      return this.taskState.getValueBySymbol('t1').content;
    },
    t2() {
      return this.taskState.getValueBySymbol('t2').content;
    },
    T1() {
      return this.taskState.getValueBySymbol('T1').content;
    },
  },
};
</script>
